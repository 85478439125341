<template>
  <ul>
    <li v-for="item in loadingItems" :key="item" class="flex justify-between mt-6">
      <div class="w-8/12 h-6 animate-pulse bg-gray-300 rounded" />
      <div class="w-1/12 h-6 animate-pulse bg-gray-300 rounded" />
      <div class="w-1/12 h-6 animate-pulse bg-gray-300 rounded" />
    </li>
  </ul>
</template>

<script>
export default {
  name: 'IncludeOptionListLoader',
  data() {
    return { loadingItems: Array(8).keys() };
  },
};
</script>
