var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-1 flex-col justify-start items-start mt-2 md:mt-4 text-sm mb-2 px-2",
    },
    [
      _c("div", { staticClass: "text-gray-600 mb-2" }, [_vm._t("title")], 2),
      _c(
        "div",
        { staticClass: "flex flex-wrap" },
        _vm._l(_vm.list, function (item, i) {
          return _c(
            "div",
            { key: `name${i}` },
            [
              _vm._t(
                "item",
                function () {
                  return [
                    _c(
                      "sun-pill",
                      {
                        staticClass: "text-xs m-1",
                        attrs: {
                          close: _vm.canRemove,
                          color: _vm.color,
                          "color-fix": "200",
                          opacity: "50",
                        },
                        on: {
                          close: function ($event) {
                            return _vm.removeItem(item)
                          },
                        },
                      },
                      [
                        _c(
                          "span",
                          { class: ["font-bold", `text-${_vm.color}-900`] },
                          [
                            _vm._t(
                              "item-text",
                              function () {
                                return [_vm._v(" " + _vm._s(item) + " ")]
                              },
                              { item: item }
                            ),
                          ],
                          2
                        ),
                      ]
                    ),
                  ]
                },
                { item: { item } }
              ),
            ],
            2
          )
        }),
        0
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }