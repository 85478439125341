var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      { staticClass: "overflow-y-auto h-full" },
      [
        _c(
          "fixed-header-container",
          {
            scopedSlots: _vm._u(
              [
                {
                  key: "header",
                  fn: function () {
                    return [
                      _c(
                        "div",
                        {
                          staticClass:
                            "flex w-full px-4 py-2 bg-gray-300 justify-between items-center text-gray-700 font-bold",
                        },
                        [
                          _vm._t("title", function () {
                            return [_vm._v(" Includes ")]
                          }),
                        ],
                        2
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              true
            ),
          },
          [
            _c(
              "transition-group",
              { attrs: { name: "fade" } },
              _vm._l(_vm.items, function (item, index) {
                return _c(
                  "div",
                  {
                    key: `item${index}`,
                    staticClass:
                      "flex items-center justify-between w-full px-4 py-2 bg-gray-100 hover:bg-gray-200",
                    attrs: { id: `item${index}` },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "text-sm text-gray-800 font-bold" },
                      [
                        _vm._t(
                          "item",
                          function () {
                            return [_vm._v(_vm._s(item.name))]
                          },
                          { item: item }
                        ),
                      ],
                      2
                    ),
                    _c(
                      "div",
                      {
                        staticClass:
                          "text-gray-600 p-2 cursor-pointer hover:text-red-700",
                        on: {
                          click: function ($event) {
                            return _vm.remove(item)
                          },
                        },
                      },
                      [_c("close-svg", { staticClass: "w-3" })],
                      1
                    ),
                  ]
                )
              }),
              0
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }