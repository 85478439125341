<template>
  <asterix-modal modal-class="asterix-modal-size h-full" :title="title" closable @cancel="$emit('cancel')">
    <template slot="content">
      <div class="flex h-full w-full h-80vh overflow-hidden">
        <div class="w-1/2 p-1 relative flex flex-col">
          <target-header-modal
            :can-include="isIncludeMultiple"
            :can-exclude="allowExclude"
            :is-include-selected="isTypeInclude"
            :is-exclude-selected="isTypeExclude"
            :loading="isLoading"
            @search="search"
            @include="includeAllItems"
            @exclude="excludeAllItems"
          ></target-header-modal>

          <include-option-list-loader v-if="isLoading" class="px-2 overflow-hidden"></include-option-list-loader>
          <target-options-list
            v-else
            ref="fixedContainer"
            :can-include="!isTypeSelected || isTypeInclude"
            :can-exclude="!isTypeSelected || isTypeExclude"
            :includes="includes"
            :excludes="excludes"
            :is-exclude-visible="allowExclude"
            :options="optionsFiltered"
            :multiple="isIncludeMultiple"
            @include="onIncludePick"
            @exclude="onExcludePick"
          >
            <template #option="{ option }">
              {{ getOptionName(option) }}
            </template>
          </target-options-list>
        </div>

        <!-- Includes and excludes list -->
        <div class="w-1/2">
          <asterix-no-data
            v-if="include.value.length === 0"
            title="There aren't items added"
            subtitle="You can add them using the controls on the option list"
            class="text-center p-2"
          />
          <target-options-selected-list v-else :items="include.value" @remove="deleteFromList">
            <template #title> {{ include.getTypeName() }} </template>
            <template #item="{ item }">
              {{ getOptionNameById(item) }}
            </template>
          </target-options-selected-list>
        </div>
      </div>
    </template>
    <!-- Bottom buttons -->
    <template #footer>
      <sun-button color="orange" variant="pill" class="custom-p-1 text-sm mr-2" @click="addInclude"> Apply </sun-button>
      <sun-button variant="pill" color="white" class="custom-p-1 text-sm hover:bg-gray-100" @click="cancelInclude">
        Cancel
      </sun-button>
    </template>
  </asterix-modal>
</template>
<script>
import AsterixModal from '@/components/organisms/shared/AsterixModal';
import IncludeItem from '@/model/shared/IncludeItem';
import IncludeOptionListLoader from '@/components/atoms/Loaders/IncludeOptionListLoader';
import TargetHeaderModal from './TargetHeaderModal';
import TargetOptionsList from './TargetOptionsList';
import TargetOptionsSelectedList from './TargetOptionsSelectedList';
import AsterixNoData from '@/components/organisms/shared/AsterixNoData';

export default {
  name: 'IncludeModal',
  components: {
    IncludeOptionListLoader,
    AsterixModal,
    TargetHeaderModal,
    TargetOptionsList,
    TargetOptionsSelectedList,
    AsterixNoData,
  },
  inject: ['services'],
  props: {
    /** @Type<IncludeItem> */
    baseInclude: {
      type: Object,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    paginated: {
      type: Boolean,
      default: false,
    },
    itemsPerPage: {
      type: Number,
      default: 50,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isLoading: false,
      options: [],
      searchTerm: null,
      include: null,
      currentPage: 1,
    };
  },
  computed: {
    includes() {
      return this.isTypeInclude ? this.include.value : [];
    },
    excludes() {
      return this.isTypeExclude ? this.include.value : [];
    },
    isTypeSelected() {
      return this.include?.type !== IncludeItem.VALID_TYPES.NONE;
    },
    isTypeInclude() {
      return this.include?.type === IncludeItem.VALID_TYPES.INCLUDE;
    },
    isTypeExclude() {
      return this.include?.type === IncludeItem.VALID_TYPES.EXCLUDE;
    },
    optionsFiltered() {
      let list = this.options;

      if (this.searchTerm && !this.paginated) {
        const termLowerCase = this.searchTerm.toLowerCase();
        list = list.filter(item => {
          const itemText = item?.name || item?.id || item;
          return itemText.toLowerCase().indexOf(termLowerCase) > -1;
        });
      }

      return list;
    },
    isIncludeMultiple() {
      return this.include.multiple;
    },
  },
  async beforeMount() {
    this.include = IncludeItem.deepClone(this.baseInclude);
    await this.fillOptions();
  },
  beforeDestroy() {
    if (this.paginated) this.deleteScrollEvent();
  },
  methods: {
    async fillOptions() {
      this.isLoading = true;
      this.options = this.options.concat(
        (await this.services[this.baseInclude.id]?.(this.searchTerm, this.currentPage, this.itemsPerPage))?.data
      );
      this.isLoading = false;
      if (this.paginated) {
        this.$nextTick(() => {
          this.bindScrollEvent();
        });
      }
    },
    search(value) {
      this.searchTerm = value;
      if (this.paginated) {
        this.currentPage = 1;
        this.options = [];
        this.fillOptions();
      }
    },
    onExcludePick(option) {
      if (this.isTypeExclude || !this.isTypeSelected) {
        this.include.type = IncludeItem.VALID_TYPES.EXCLUDE;
        this.onPick(option);
      }
    },
    onIncludePick(option) {
      if (this.isTypeInclude || !this.isTypeSelected) {
        this.include.type = IncludeItem.VALID_TYPES.INCLUDE;
        this.onPick(option);
      }
    },
    onPick(option) {
      if (this.isInList(option)) {
        this.deleteFromList(option);
      } else {
        this.include.value.push(option);
      }
    },
    includeAllItems() {
      if (!this.isLoading) {
        this.onAddAll(IncludeItem.VALID_TYPES.INCLUDE);
      }
    },
    excludeAllItems() {
      if (!this.isLoading) {
        this.onAddAll(IncludeItem.VALID_TYPES.EXCLUDE);
      }
    },
    onAddAll(type) {
      if (type !== this.include.type || this.include.value.length !== this.options.length) {
        this.include.value = [...this.options];
        this.include.type = type;
      } else {
        this.include.value = [];
        this.include.type = IncludeItem.VALID_TYPES.NONE;
      }
    },
    deleteFromList(option) {
      const list = this.include.value;
      const index = list.findIndex(item => item.id === option.id);
      list.splice(index, 1);

      if (list.length === 0) {
        this.include.type = IncludeItem.VALID_TYPES.NONE;
      }
    },
    isInList(option) {
      return !!this.include.value.find(value => value.id === option.id);
    },
    isInListAndInclude(option) {
      return this.isTypeInclude && this.isInList(option);
    },
    isInListAndExclude(option) {
      return this.isTypeExclude && this.isInList(option);
    },
    addInclude() {
      this.$emit('update', this.include);
    },
    cancelInclude() {
      this.$emit('cancel');
    },
    getOptionValue(option) {
      return option?.id ? option.id : option;
    },
    getOptionNameById(item) {
      const id = item?.id || item;
      const name = this.getOptionName(this.options.find(t => t?.id === id));
      if (!name) {
        const value = this.include.value.find(val => val.id === id);
        return value.name;
      }
      return name;
    },
    getOptionName(option) {
      return option?.name || option?.getDisplayName?.() || option?.id || option;
    },
    bindScrollEvent() {
      const contentElement = this.$refs.fixedContainer.$refs.container;
      if (contentElement) {
        this.$nextTick(() => {
          const percent = ((this.currentPage - 1) * this.itemsPerPage * 0.5) / (this.currentPage * this.itemsPerPage);
          contentElement.scrollTop = percent * contentElement.scrollTopMax;
        });
        contentElement.addEventListener('scroll', this.scrollEvent);
        this.isBindedScroll = true;
      }
    },
    scrollEvent(event) {
      const targetElement = event.target;
      const percent = (targetElement.scrollTop / targetElement.scrollTopMax) * 100;
      if (percent > 50 && !this.isLoading) {
        this.currentPage++;
        this.fillOptions();
      } else if (this.isLoading) {
        this.deleteScrollEvent();
      }
    },
    deleteScrollEvent() {
      const contentElement = this.$refs.fixedContainer.$refs.container;
      if (contentElement) {
        contentElement.removeEventListener('scroll', this.scrollEvent);
      }
    },
    isIncludeOptionsAllowed(option) {
      const isOptionSelected = this.include.value.findIndex(item => item.id === option.id) >= 0;

      return this.isIncludeMultiple || !this.include.value.length || isOptionSelected;
    },
  },
};
</script>
<style scoped>
.h-80vh {
  height: 80vh;
}

.modal-column {
  @apply p-1 overflow-hidden;
}
</style>
