var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "asterix-modal",
    {
      attrs: {
        "modal-class": "asterix-modal-size h-full",
        title: _vm.title,
        closable: "",
      },
      on: {
        cancel: function ($event) {
          return _vm.$emit("cancel")
        },
      },
      scopedSlots: _vm._u([
        {
          key: "footer",
          fn: function () {
            return [
              _c(
                "sun-button",
                {
                  staticClass: "custom-p-1 text-sm mr-2",
                  attrs: { color: "orange", variant: "pill" },
                  on: { click: _vm.addInclude },
                },
                [_vm._v(" Apply ")]
              ),
              _c(
                "sun-button",
                {
                  staticClass: "custom-p-1 text-sm hover:bg-gray-100",
                  attrs: { variant: "pill", color: "white" },
                  on: { click: _vm.cancelInclude },
                },
                [_vm._v(" Cancel ")]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("template", { slot: "content" }, [
        _c(
          "div",
          { staticClass: "flex h-full w-full h-80vh overflow-hidden" },
          [
            _c(
              "div",
              { staticClass: "w-1/2 p-1 relative flex flex-col" },
              [
                _c("target-header-modal", {
                  attrs: {
                    "can-include": _vm.isIncludeMultiple,
                    "can-exclude": _vm.allowExclude,
                    "is-include-selected": _vm.isTypeInclude,
                    "is-exclude-selected": _vm.isTypeExclude,
                    loading: _vm.isLoading,
                  },
                  on: {
                    search: _vm.search,
                    include: _vm.includeAllItems,
                    exclude: _vm.excludeAllItems,
                  },
                }),
                _vm.isLoading
                  ? _c("include-option-list-loader", {
                      staticClass: "px-2 overflow-hidden",
                    })
                  : _c("target-options-list", {
                      ref: "fixedContainer",
                      attrs: {
                        "can-include": !_vm.isTypeSelected || _vm.isTypeInclude,
                        "can-exclude": !_vm.isTypeSelected || _vm.isTypeExclude,
                        includes: _vm.includes,
                        excludes: _vm.excludes,
                        "is-exclude-visible": _vm.allowExclude,
                        options: _vm.optionsFiltered,
                        multiple: _vm.isIncludeMultiple,
                      },
                      on: {
                        include: _vm.onIncludePick,
                        exclude: _vm.onExcludePick,
                      },
                      scopedSlots: _vm._u([
                        {
                          key: "option",
                          fn: function ({ option }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getOptionName(option)) + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "w-1/2" },
              [
                _vm.include.value.length === 0
                  ? _c("asterix-no-data", {
                      staticClass: "text-center p-2",
                      attrs: {
                        title: "There aren't items added",
                        subtitle:
                          "You can add them using the controls on the option list",
                      },
                    })
                  : _c("target-options-selected-list", {
                      attrs: { items: _vm.include.value },
                      on: { remove: _vm.deleteFromList },
                      scopedSlots: _vm._u([
                        {
                          key: "title",
                          fn: function () {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.include.getTypeName()) + " "
                              ),
                            ]
                          },
                          proxy: true,
                        },
                        {
                          key: "item",
                          fn: function ({ item }) {
                            return [
                              _vm._v(
                                " " + _vm._s(_vm.getOptionNameById(item)) + " "
                              ),
                            ]
                          },
                        },
                      ]),
                    }),
              ],
              1
            ),
          ]
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }