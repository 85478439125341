<template>
  <div class="flex flex-col justify-start items-start w-full p-4 border-gray-200">
    <div class="flex flex-col flex-row w-full">
      <div class="flex w-full justify-start items-start justify-between">
        <span class="text-base text-gray-700 font-bold">
          {{ include.name }}
        </span>
        <div class="flex w-full items-center flex-1 justify-end">
          <sun-button
            class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative"
            color="gray"
            :disabled="readOnly"
            variant="pill"
            @click="emitEdit(include)"
          >
            Edit
          </sun-button>
          <div
            v-if="hasValue && collapsible"
            class="ml-4 p-1 text-xs text-gray-700 hover:text-gray-900 cursor-pointer"
            @click="openList = !openList"
          >
            <sun-arrow-down-svg :class="{ 'rotate-180': openList }"></sun-arrow-down-svg>
          </div>
        </div>
      </div>
      <item-list
        v-if="openList || !collapsible"
        :color="includeTypeColor"
        :list="include.value"
        :can-remove="!readOnly"
        v-on="$listeners"
      >
        <template v-if="allowExclude" #title> {{ typeName }} </template>
        <template #item-text="{ item }">
          <slot name="item-text" :item="item">
            <span class="font-bold text-green-900"> {{ item | name(filterName) }} </span>
          </slot>
        </template>
      </item-list>
    </div>
  </div>
</template>
<script>
import IncludeItem from '@/model/shared/IncludeItem';
import ItemList from './ItemList';

export default {
  name: 'IncludeItemList',
  filters: {
    name(value, filter) {
      return filter(value);
    },
  },
  components: {
    ItemList,
  },
  props: {
    /** @Type<IncludeItem> */
    include: {
      type: Object,
      required: true,
    },
    collapsible: {
      type: Boolean,
      default: () => false,
    },
    filterName: {
      type: Function,
      default: value => value,
    },
    allowExclude: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      openList: false,
    };
  },
  computed: {
    typeName() {
      return IncludeItem.VALID_TYPES_NAMES[this.include.type];
    },
    hasValue() {
      return this.include.type !== IncludeItem.VALID_TYPES.NONE;
    },
    includeTypeColor() {
      return this.include.type === IncludeItem.VALID_TYPES.INCLUDE ? 'green' : 'red';
    },
  },
  methods: {
    emitEdit(key) {
      this.$emit('edit', key);
    },
  },
};
</script>
