<template>
  <div>
    <div class="overflow-y-auto h-full">
      <fixed-header-container>
        <template #header>
          <div class="flex w-full px-4 py-2 bg-gray-300 justify-between items-center text-gray-700 font-bold">
            <slot name="title"> Includes </slot>
          </div>
        </template>

        <transition-group name="fade">
          <div
            v-for="(item, index) in items"
            :id="`item${index}`"
            :key="`item${index}`"
            class="flex items-center justify-between w-full px-4 py-2 bg-gray-100 hover:bg-gray-200"
          >
            <div class="text-sm text-gray-800 font-bold">
              <slot name="item" :item="item">{{ item.name }}</slot>
            </div>
            <div class="text-gray-600 p-2 cursor-pointer hover:text-red-700" @click="remove(item)">
              <close-svg class="w-3"></close-svg>
            </div>
          </div>
        </transition-group>
      </fixed-header-container>
    </div>
  </div>
</template>
<script>
import FixedHeaderContainer from '@/components/molecules/shared/FixedHeaderContainer';
import CloseSvg from '@/components/icons/CloseSvg';

export default {
  name: 'TargetOptionsSelectedList',
  components: {
    FixedHeaderContainer,
    CloseSvg,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    remove(item) {
      this.$emit('remove', item);
    },
  },
};
</script>
