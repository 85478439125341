<template>
  <div class="inline-flex w-full">
    <sun-search-input :value="searchTerm" class="flex-1 mr-3 mb-2" @search="search" />
    <div v-show="canInclude || canExclude" class="flex pr-5">
      <div v-if="canInclude" @click="includeAll">
        <sun-popover
          class="mr-4 hover:text-green-700 cursor-pointer text-gray-500 hover:text-gray-400"
          :class="{
            'text-green-700 hover:text-green-500': isIncludeSelected,
            'cursor-not-allowed': loading,
          }"
          text-info="Include all items"
        >
          <check-svg class="w-4 m-2"></check-svg>
        </sun-popover>
      </div>
      <div v-if="canExclude" class="mr-2" @click="excludeAll">
        <sun-popover
          class="mr-4 cursor-pointer text-gray-500 hover:text-gray-400"
          :class="{
            'text-red-700 hover:text-red-500': isExcludeSelected,
            'cursor-not-allowed': loading,
          }"
          text-info="Exclude all items"
        >
          <exclude-svg class="w-4 m-2"></exclude-svg>
        </sun-popover>
      </div>
    </div>
  </div>
</template>
<script>
import CheckSvg from '@/components/icons/CheckSvg';
import ExcludeSvg from '@/components/icons/ExcludeSvg';

export default {
  name: 'TargetHeaderModal',
  components: {
    CheckSvg,
    ExcludeSvg,
  },
  props: {
    canInclude: {
      type: Boolean,
      default: false,
    },
    canExclude: {
      type: Boolean,
      default: false,
    },
    isIncludeSelected: {
      type: Boolean,
      default: false,
    },
    isExcludeSelected: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
    };
  },
  methods: {
    search(value) {
      this.searchTerm = value;
      this.$emit('search', value);
    },
    includeAll() {
      this.$emit('include');
    },
    excludeAll() {
      this.$emit('exclude');
    },
  },
};
</script>
