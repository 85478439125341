var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "ul",
    _vm._l(_vm.loadingItems, function (item) {
      return _c("li", { key: item, staticClass: "flex justify-between mt-6" }, [
        _c("div", {
          staticClass: "w-8/12 h-6 animate-pulse bg-gray-300 rounded",
        }),
        _c("div", {
          staticClass: "w-1/12 h-6 animate-pulse bg-gray-300 rounded",
        }),
        _c("div", {
          staticClass: "w-1/12 h-6 animate-pulse bg-gray-300 rounded",
        }),
      ])
    }),
    0
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }