var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass:
        "flex flex-col justify-start items-start w-full p-4 border-gray-200",
    },
    [
      _c(
        "div",
        { staticClass: "flex flex-col flex-row w-full" },
        [
          _c(
            "div",
            {
              staticClass:
                "flex w-full justify-start items-start justify-between",
            },
            [
              _c("span", { staticClass: "text-base text-gray-700 font-bold" }, [
                _vm._v(" " + _vm._s(_vm.include.name) + " "),
              ]),
              _c(
                "div",
                { staticClass: "flex w-full items-center flex-1 justify-end" },
                [
                  _c(
                    "sun-button",
                    {
                      staticClass:
                        "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800 relative",
                      attrs: {
                        color: "gray",
                        disabled: _vm.readOnly,
                        variant: "pill",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.emitEdit(_vm.include)
                        },
                      },
                    },
                    [_vm._v(" Edit ")]
                  ),
                  _vm.hasValue && _vm.collapsible
                    ? _c(
                        "div",
                        {
                          staticClass:
                            "ml-4 p-1 text-xs text-gray-700 hover:text-gray-900 cursor-pointer",
                          on: {
                            click: function ($event) {
                              _vm.openList = !_vm.openList
                            },
                          },
                        },
                        [
                          _c("sun-arrow-down-svg", {
                            class: { "rotate-180": _vm.openList },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]
          ),
          _vm.openList || !_vm.collapsible
            ? _c(
                "item-list",
                _vm._g(
                  {
                    attrs: {
                      color: _vm.includeTypeColor,
                      list: _vm.include.value,
                      "can-remove": !_vm.readOnly,
                    },
                    scopedSlots: _vm._u(
                      [
                        _vm.allowExclude
                          ? {
                              key: "title",
                              fn: function () {
                                return [
                                  _vm._v(" " + _vm._s(_vm.typeName) + " "),
                                ]
                              },
                              proxy: true,
                            }
                          : null,
                        {
                          key: "item-text",
                          fn: function ({ item }) {
                            return [
                              _vm._t(
                                "item-text",
                                function () {
                                  return [
                                    _c(
                                      "span",
                                      {
                                        staticClass: "font-bold text-green-900",
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm._f("name")(
                                                item,
                                                _vm.filterName
                                              )
                                            ) +
                                            " "
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                                { item: item }
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      true
                    ),
                  },
                  _vm.$listeners
                )
              )
            : _vm._e(),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }