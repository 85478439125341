<template>
  <div class="flex flex-1 flex-col justify-start items-start mt-2 md:mt-4 text-sm mb-2 px-2">
    <div class="text-gray-600 mb-2">
      <slot name="title"></slot>
    </div>
    <div class="flex flex-wrap">
      <div v-for="(item, i) in list" :key="`name${i}`">
        <slot name="item" :item="{ item }">
          <sun-pill
            class="text-xs m-1"
            :close="canRemove"
            :color="color"
            color-fix="200"
            opacity="50"
            @close="removeItem(item)"
          >
            <span :class="['font-bold', `text-${color}-900`]">
              <slot name="item-text" :item="item">
                {{ item }}
              </slot>
            </span>
          </sun-pill>
        </slot>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ItemList',
  props: {
    list: {
      type: Array,
      required: true,
    },
    color: {
      type: String,
      default: 'green',
    },
    canRemove: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removeItem(item) {
      this.$emit('remove', item.id);
    },
  },
};
</script>
